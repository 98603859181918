import { Stack } from "@mui/material";
import React, { FC } from "react";

import { HelpArticleLink } from "components/library/HelpArticleLink";
import { BodySmall } from "components/library/typography";
import { colors } from "styles/theme";
import { H2 } from "styles/typography/headers";
import NewTemplateButton from "views/CompanySetup/components/EmailTemplates/components/NewTemplateButton";
import { CompanySetupSectionConfig } from "views/CompanySetup/constants";

interface SectionWrapperProps {
  config: CompanySetupSectionConfig;
}

const SectionWrapper: FC<React.PropsWithChildren<SectionWrapperProps>> = ({ children, config }) => {
  return (
    <Stack maxWidth={"1400px"} width={"100%"} flexGrow={1} spacing={2}>
      <Stack spacing={1}>
        <Stack width="100%" justifyContent="space-between" direction="row">
          {config.sectionLabel && <H2>{config.sectionLabel}</H2>}
          {config.helpDeskArticle && <HelpArticleLink linkUrl={config.helpDeskArticle} />}
        </Stack>
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Stack width={config.sectionLabel === "Email Templates" ? "88%" : "100%"}>
            {config.description && <BodySmall color={colors.grayscale.gray600}>{config.description}</BodySmall>}
          </Stack>
          {config.sectionLabel === "Email Templates" && <NewTemplateButton style={{ marginLeft: "auto" }} />}
        </Stack>
      </Stack>
      {children}
    </Stack>
  );
};

export default SectionWrapper;
