import { Stack } from "@mui/material";
import React, { FC } from "react";

import { ReactComponent as HelpIcon } from "assets/icons/help-question.svg";
import { Body } from "components/library/typography";
import { colors } from "styles/theme";
import { ExternalLink } from "styles/typography";

interface HelpArticleLinkProps {
  linkUrl: string;
  label?: string;
}

export const HelpArticleLink: FC<React.PropsWithChildren<HelpArticleLinkProps>> = ({
  linkUrl,
  label,
}: HelpArticleLinkProps) => {
  return (
    <ExternalLink display="inline" href={linkUrl} target="_blank" rel="noopener noreferrer">
      <Stack direction="row" spacing={0.5} alignItems="center">
        <HelpIcon color={colors.link} className="svg-color" />
        <Body color={colors.link}>{label ?? "Help Article"}</Body>
      </Stack>
    </ExternalLink>
  );
};
